@import "../variables.scss";

.ctn {
  padding-top: 60px;
  padding-bottom: 90px;
  height: 100%;
  background-color: transparent;
  color: rgb(0, 0, 0);
  background-color: white;
}

.form {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}

// .ctnImput {
//   display: flex;
//   justify-content: flex-start;
//   align-items: flex-start;
//   flex-direction: column;
// }

.title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 0.9rem;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 1px;
  @include medium {
    font-size: 0.8rem;
  }
}

.input {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  width: 600px;
  border: 1px solid #767676;
  border-radius: 6px;
  @include medium {
    padding: 0.25rem 0.5rem;
  }
  @include smallInte {
    width: 400px;
  }
  @include smallSmall {
    width: 330px;
  }
}

.textarea {
  display: flex;
  height: 100px;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  width: 600px;
  border: 1px solid #767676;
  border-radius: 6px;
  @include smallInte {
    width: 400px;
  }

  @include smallSmall {
    width: 330px;
  }
}

.error {
  color: red;
  font-size: 0.8rem;
  letter-spacing: 0px;
  margin-bottom: 0px;
}

.btn {
  width: 600px;
  margin-top: 30px;
  padding: 0.8em 1.5em;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0);
  color: rgb(255, 255, 255);
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;
  background-color: #000000;
  border: 1px solid rgb(0, 0, 0);
  transition: all 0.2s;
  cursor: pointer;
  @include smallInte {
    width: 400px;
    padding: 0.6em 1.5em;
  }
  @include smallSmall {
    width: 330px;
  }
}

.btnModal:hover,
.btn:hover {
  background-color: #000000dc;
}

.titleSup {
  padding-bottom: 20px;
  font-size: 40px;
  letter-spacing: 2px;
  font-family: "Dosis", sans-serif;
  text-align: center;
  @include medium {
    font-size: 30px;
    padding-top: 30px;
  }
}

.ctnSupDate {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  @include smallInteSmall {
    // background-color: red;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 160px;
  }
  @include smallSmall {
    margin-right: 100px;
  }
}

.date {
  display: flex;
  align-items: center;
  margin: 10px 25px 10px 25px;
  @include smallInte {
    margin: 10px;
  }
}

.icon {
  font-size: 20px;
  background-color: #000000;
  color: white;
  padding: 2px;
}

.ctnTxt {
  margin: 0px;
  text-align: start;
  margin-left: 10px;
}

.txt {
  margin: 0 auto;
  font-size: 15px;
  letter-spacing: 2px;
  font-family: "Dosis", sans-serif;
}

.txt2 {
  font-family: "Montserrat", sans-serif;
  margin: 0 auto;
  font-size: 12px;
  letter-spacing: 1px;
}

.a {
  text-decoration: none;
  color: black;
  transition: all 0.1s;
}

.a:hover {
  color: black;
  transform: scale(1.03);
}

.ctnBtModal {
  justify-content: flex-start;
}

.btnModal {
  width: 150px;
  padding: 0.5em;
  border: 1px solid rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;
  background-color: #000000;
  cursor: pointer;
  transition: all 0.2s;
}
